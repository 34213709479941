<template v-slot:footer>
  <div id="table-footer" class="v-data-footer">
    {{ items ? items.length : 0 }}
    {{ items && items.length === 1 ? labelSingular : label }}
  </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    items: { type: Array },
    label: { type: String, default: "Zeilen" },
    labelSingular: { type: String, default: "Zeile" },
  },
});
</script>
<style scoped>
#table-footer {
  padding: 8pt 16pt;
  font-size: 0.75em;
}
</style>
