<template>
  <v-dialog v-model="dialogVisible" width="400" scrollable>
    <template v-slot:activator="{ on }">
      <SchoolClass
        v-bind="$attrs"
        v-on="on"
        :value="modelValue"
        v-if="listItem"
      />
      <v-btn
        v-else
        v-bind="$attrs"
        v-on="on"
        style="text-transform: capitalize"
      >
        {{ modelValue.code }}
        <v-icon right v-if="items.length > 1">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-system-bar window
        >Klasse wählen<v-spacer /><v-icon @click="close"
          >mdi-close</v-icon
        ></v-system-bar
      >
      <v-list>
        <v-list-item-group v-model="modelValue" mandatory>
          <SchoolClass v-for="item in items" :key="item.id" :value="item" />
        </v-list-item-group>
      </v-list>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn block text @click="close">Abbrechen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { defineComponent } from "vue";
import SchoolClass from "@/components/SchoolClass.vue";

export default defineComponent({
  name: "SchoolClassPicker",
  components: { SchoolClass },
  props: {
    listItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dialogVisible: false,
      items: [],
      modelValue: null,
    };
  },
  watch: {
    modelValue() {
      this.$emit("input", this.modelValue);
      window.localStorage.setItem("SchoolClassPicker", this.modelValue.id);
      this.close();
    },
  },
  async created() {
    this.items = this.$_classTeacherOf;
    // select previously selected item
    const selectedId = Number.parseInt(
      window.localStorage.getItem("SchoolClassPicker")
    );
    this.modelValue = this.items.find((item) => item.id === selectedId);
    if (!this.modelValue && this.items.length > 0) {
      this.modelValue = this.items[0];
    }
  },
  methods: {
    close() {
      this.dialogVisible = false;
    },
  },
});
</script>
